import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
    footer:{
        display: 'flex',
        flexDirection: 'column',
        height: "60vh",
        minWidth: "100%",
        backgroundColor: "#303030",
        opacity: "",
        color: 'white',
        fontWeight: '', 
        marginLeft: 'auto',
        justifyContent: 'center'
    },footerTop:{
        width: '70%',
        display: 'flex',
        minHeight: '15vh',
        margin: 'auto',
        justifyContent: 'space-around',

    },footerTopText:{
        justifyContent: 'center',
        width: '30%',
        [theme.breakpoints.down('lg')]: {
            paddingTop: "20px",
        }
    }
    ,footerBottom:{
        display:'flex',
        height: '25vh',
        width: '90%',
        borderTop: "1px solid gray",
        margin: 'auto',
        alighItems: 'center',
    }
}));
import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import cctv from "../../images/cctv_new.jpeg";
import useStyles from './styles';
import CCTV from './CCTV/CCTV';
import ACS from './ACS/ACS';
import Cabling from './Cabling/Cabling';
import ContactUS from './ContactUS/ContactUS';
import Banner from './Banner/Banner';


const Home = () => {
  const classes = useStyles();
  return (

    <Box>
      <Box className={classes.banner} sx={{width: "80%", minHeight: "25vh" , margin: "auto" }} >
        <Banner />
      </Box>
      <Box className={classes.image3} sx={{width: "80%", Height: {xs: "30vh",sm: "30vh",md: "30vh",lg: "50vh",xl: "50vh"} , margin: "auto", }} data-aos="fade-down" data-aos-duration="1500">
        <Cabling />
      </Box>
      <Box className={classes.last} sx={{width: "80%", Height: "100vh" , margin: "auto", }} data-aos="fade-down" data-aos-duration="1500">
        <ContactUS />
      </Box>
      <Box className={classes.image1} sx={{width: "80%", Height: {xs: "30vh",sm: "30vh",md: "30vh",lg: "50vh",xl: "50vh"} , margin: "auto", }} data-aos="fade-down" data-aos-duration="1000">
        <CCTV />
      </Box>
      <Box className={classes.image2} sx={{width: "80%", Height: {xs: "30vh",sm: "30vh",md: "30vh",lg: "50vh",xl: "50vh"} , margin: "auto", }} data-aos="fade-down" data-aos-duration="1500">
        <ACS />
      </Box>
    </Box>
    
  )
}

export default Home
import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import useStyles from './styles';
import Card1 from './Card1/Card1';
import Card2 from './Card2/Card2';
import Card3 from './Card3/Card3';

function ContactUS(props) {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <Box className={classes.title} sx={{minHeight: {sx: '140vh', sm: '110vh',md: '110vh',lg: '110vh',xl: '110vh'}}}>
                <Box>
                    <Typography fontSize="3vw" color="black" fontWeight="bold" sx={{paddingTop: "20px", paddingBottom: "20px", fontSize:{xs: "3.5vw",sm: "3.5vw",md: "3vw",lg: "3vw",xl: "3vw"}}}>       
                        We secure businesses of all sizes
                    </Typography>
                </Box>
                <Grid container className={classes.cardbox}>
                    <Grid item xs={12} sm={12} md={12} lg={4} className={classes.card}>
                        <Card1/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} className={classes.card}> 
                        <Card2/>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} className={classes.card}>
                        <Card3/>
                    </Grid>
                    
                </Grid>
                <Box sx={{paddingTop: {xs: "20px", sm: "0px", md:"0px", lg:"0px", xl:"0px"}}}>
                <Button variant="contained" size="large" ><b>Contact Us</b></Button>
                </Box>
                
            </Box>
            
            
        </div>
    );
}

export default ContactUS;
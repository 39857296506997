import React from 'react';
import useStyles from './styles';
import { Box, Button, Typography } from '@mui/material';


function CCTV(props) {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <Box className={classes.title}>
                 <Typography sx={{display: {xs: "none", sm: "block", md:"block", lg:"block", xl:"block"}}}fontSize="2.2vw" color="black" fontWeight="bold">       
                     Amplify Your Peace of Mind: 
                    <br/>Discover High-Security Solutions    
                    <hr  style={{borderTop: "3px solid black", width: "50%"}}/>
                </Typography>
                <Typography sx={{display: {sm: "none", md:"none", lg:'none', xl:'none'}}}fontSize="3vw" color="black" fontWeight="bold">       
                    Discover High-Security Solutions    
                    <hr  style={{borderTop: "3px solid black", width: "50%"}}/>
                </Typography>
                <Typography sx={{fontSize: {xs: "4vw", sm: "3vw", md: "1.5vw", lg: "1.5vw", xl: "1vw"}, display: {xs:"none", sm:"none", md:"block", lg:"block", xl: "block"}}} fontWeight="bold">
                    Over Ten years of expeirence in Security Industry.
                    <br/>Click below to find out more about the company.
                    <br/>
                    <br/>
                    <Button variant="contained" href="/about" size='large'><b>About Us</b></Button>
                </Typography>
                <Typography sx={{fontSize: {xs: "3vw", sm: "2.2vw", md: "1vw", lg: "1vw", xl: "1vw"}, display: {xs:"block", sm:"block", md:"none", lg:"none", xl: "none"}}} fontWeight="bold">
                    Learn more about us!
                    <br/>
                    <Button variant="contained" href="/about" size='large'><b>About Us</b></Button>
                </Typography>
            </Box>
            
        </div>
    );
}

export default CCTV;
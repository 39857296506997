import { makeStyles } from '@material-ui/core/styles';
import cctv from "../../images/cctv_new.jpeg";
import acs from "../../images/acs.jpeg";
import cabling from "../../images/cabling.png";

export default makeStyles({
    image1:{
        position: 'relative',
        display: 'flex',
        color: 'black',
        '&::before':{
            content: '""',
            backgroundImage: `url(${cctv})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            position: "absolute",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
            zIndex: '-1',
            opacity: "0.2"
        }
    },image2:{
        position: 'relative',
        '&::before':{
            content: '""',
            backgroundImage: `url(${acs})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            position: "absolute",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
            zIndex: '-1',
            opacity: "0.2"
        }
    },image3:{
        position: 'relative',
        '&::before':{
            content: '""',
            backgroundImage: `url(${cabling})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            position: "absolute",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px",
            zIndex: '-1',
            opacity: "0.2"
        }
    },last:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        margin: 'auto'
    }, banner:{
        
    }
    
    
    
});     
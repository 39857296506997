import React from 'react'
import { AppBar, Link, Toolbar, Box, IconButton, Menu, MenuItem, Typography, Container, Button, Tooltip, Avatar } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../images/image12.png';
import AdbIcon from '@mui/icons-material/Adb';

const Navbar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
  
    const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };
  
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };
  
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };
  
    return (
      <AppBar position="sticky" sx={{backgroundColor: "white"}}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
          <Link href="/" sx={{display: { xs: 'none', md: 'flex' }}}>
            <Box component="img" pt={1} sx={{height: 60}} src={logo}/>
          </Link>
  
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="black"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >

                  <MenuItem onClick={handleCloseNavMenu} >
                    <Link underline="none" href="/" variant="body2" color="black">Home </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseNavMenu} >
                    <Link underline="none" href="/about" variant="body2" color="black">About </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Link underline="none" href="/projects" variant="body2" color="black">Projects </Link>
                  </MenuItem>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Link underline="none" href="/contact" variant="body2" color="black"> Contact Us </Link>
                  </MenuItem>
                
              </Menu>
            </Box>
            <Link href="/" sx={{display: { xs: 'flex', md: 'none' }}} flexGrow={1}>
                <Box component="img" pt={1} sx={{height: 60}} src={logo}/>
             </Link>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent: "right" } }}>

                
                
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'black', display: 'block', fontWeight: 'bold' }}
                  href="/about"
                >
                    About
                </Button>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'black', display: 'block', fontWeight: 'bold' }}
                  href="/projects"
                >
                    Projects
                </Button>
                <Button
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: 'black', display: 'block', fontWeight: 'bold' }}
                  href="/contact"
                >
                    Contact Us
                </Button>
              
            </Box>
  
          </Toolbar>
        </Container>
      </AppBar>
    );
}

export default Navbar
import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
    main:{
        [theme.breakpoints.down('md')]: {
            paddingTop: "40px",
            paddingBottom: "40px"
          }
    },
    title:{
        display: 'flex',
        width: "80%",
        flexDirection: "column",
        justifyContent: "space-evenly",
        marginLeft: "auto",
        marginRight: "auto"
    },
    cardbox:{
        width: '100%',
        justifyItems: 'center'
        
    },
    card:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}))
import React from 'react';
import useStyles from './styles';
import { Box, Button, Typography } from '@mui/material';


function Cabling(props) {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <Box className={classes.title}>
                <Typography sx={{display: {xs: "none", sm: "block", md:"block", lg:"block", xl:"block"}}}fontSize="2.2vw" color="black" fontWeight="bold">       
                    Smart IT Solution
                    <hr  style={{borderTop: "3px solid black", width: '50%'}}/>
                </Typography>
                <Typography sx={{display: {sm: "none", md:"none", lg:'none', xl:'none'}}}fontSize="3vw" color="black" fontWeight="bold">       
                    Smart IT Solution
                    <hr  style={{borderTop: "3px solid black", width: '50%'}}/>
                </Typography>
                <Typography sx={{fontSize: {xs: "4vw", sm: "3vw", md: "1.5vw", lg: "1.5vw", xl: "1vw"}, display: {xs:"none", sm:"none", md:"block", lg:"block", xl: "block"}}} fontWeight="bold">
                    Empowering Industries with <b><u>Cutting-Edge Structured Cabling, <br/>Security - CCTV & Access Control, Fire Alarm, and Audio-Visual Systems</u></b>
                    
                </Typography>
                <Typography sx={{fontSize: {xs: "3vw", sm: "2.2vw", md: "1vw", lg: "1vw", xl: "1vw"}, display: {xs:"block", sm:"block", md:"none", lg:"none", xl: "none"}}} fontWeight="bold">
                    Empowering Industries with <b><u>Cutting-Edge Structured Cabling, <br/>Security - CCTV & Access Control, Fire Alarm, and Audio-Visual Systems</u></b>
                </Typography>
 
            </Box>
            
        </div>
    );
}

export default Cabling;
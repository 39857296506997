import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import useStyles from './styles';
import camera from '../../../images/camera3.jpeg';

function Banner(props) {
    const classes = useStyles();
    return (
        <Grid container className={classes.banner}>
            <Grid item xs={11} sm={10} md={8} lg={6} className={classes.bannerLeft}>
                <Typography sx={{fontSize: {xs: "5vw",sm: "4vw",md: "3vw",lg: "3vw",xl: "3vw"}}} >
                    <b>Get your free CCTV 
                    <br/>quotation today!</b>
                </Typography>
            </Grid>
            
            <Grid item xs={1} sm={2} md={4} lg={6} className={classes.bannerRight}>
                <Box component='img' sx={{maxHeight: '20vh', maxWidth: '12vw'}} alt='camera' src={camera}>
                </Box>
            </Grid>
        </Grid>
    );
}

export default Banner;
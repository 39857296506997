import React from 'react';
import useStyles from './styles';
import { Box, Button, Typography } from '@mui/material';


function ACS(props) {
    const classes = useStyles();
    return (
        <div className={classes.main}>
            <Box className={classes.title}>
                <Typography sx={{display: {xs: "none", sm: "block", md:"block", lg:"block", xl:"block"}}}fontSize="2.2vw" color="black" fontWeight="bold">       
                    Proven Track Record  
                    <hr  style={{borderTop: "3px solid black", width: '50%'}}/>
                </Typography>
                <Typography sx={{display: {xs: "block", sm: "none", md:"none", lg:'none', xl:'none'}}}fontSize="3vw" color="black" fontWeight="bold">       
                    Proven Track Record 
                    <hr  style={{borderTop: "3px solid black", width: '50%'}}/>
                </Typography>

                <Typography sx={{fontSize: {xs: "4vw", sm: "3vw", md: "1.5vw", lg: "1.5vw", xl: "1vw"}, display: {xs:"none", sm:"none", md:"block", lg:"block", xl: "block"}}} fontWeight="bold">
                    With proven track record, we can proudly announce we can provide the services you are looking for.
                    Check out our business areas and past projects.
                    <br/>
                    <br/>
                    <Box sx={{display:'flex', margin:'auto',justifyContent:"center"}}>
                    <Button variant="contained" href="/business" size="large"><b>Projects</b></Button>
                    </Box>
                    
                </Typography>
                <Typography sx={{fontSize: {xs: "3vw", sm: "2.2vw", md: "1vw", lg: "1vw", xl: "1vw"}, display: {xs:"block", sm:"block", md:"none", lg:"none", xl: "none"}}} fontWeight="bold">
                    Check out our past projects!
                    <Box sx={{display:'flex', margin:'auto',justifyContent:"center"}}>
                    <Button variant="contained" href="/business" size="large"><b>Projects</b></Button>
                    </Box>
                    
                </Typography>
            
            </Box>
            
        </div>
    );
}

export default ACS;
import { Box } from '@mui/material';
import React from 'react';

function Project(props) {
    return (
        <Box sx={{height: "70vh", display: "flex", alignItems:"center", justifyContent:"center"}}>
            This page is currently under construction.
            Please refer to home page
        </Box>
    );
}

export default Project;
import { makeStyles } from "@material-ui/core";
import { flushSync } from "react-dom";

export default makeStyles({
    banner:{
        display: 'flex',
        alignItems: 'center',
        height: '25vh',
    },bannerLeft:{
        width: '50%',
    },bannerRight:{
        width: '50%',
        marginBottom: 'auto'
    }

})
import { Box, Container } from '@mui/material';
import React, { useEffect } from 'react'
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from './components/Home/Home';
import Navbar from './components/Navbar/Navbar';
import About from './components/About/About';
import Project from './components/Projects/Projects';
import Footer from './components/Footer/Footer';
import AOS from 'aos';
import "aos/dist/aos.css";


const App = () => {
  useEffect(() => {
    AOS.init({once:true});
    AOS.refresh();
  }, []);
  return (
    <Box sx={{width: {xs:'100%', md: '100%', lg: '100%'}, textAlign: 'center', margin: 'auto', alignItems: 'center'}}>
      <Navbar />
        <BrowserRouter>
        <Box >
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/about" exact element={<About />} />
            <Route path="/projects" exact element={<Project  />} />
            <Route path="/contact" exact element={<Project  />} />
            
          </Routes>
        </Box>
        <Footer />
        </BrowserRouter>
      
    </Box>
  )
}

export default App
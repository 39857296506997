import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import midscale from '../../../../images/midscale.jpeg';

function Card2(props) {
    return (
<Card sx={{ maxWidth: 345, height: 580 }} elevation={10} data-aos="fade-down" data-aos-duration="1000">
      <CardMedia
        component="img"
        alt="midscale"
        height="350"
        image={midscale}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Mid-Scale
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{height: 110}}>
          Contact us for our all-in-one service which includes surveillance, alarms, video & audio systems for mid-scale commercial buidlings such as shopping malls, strips and mid-scale businesses
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
    );
}

export default Card2;
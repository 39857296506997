import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import smallscale from '../../../../images/smallscale.jpeg';

function Card3(props) {
    return (
<Card sx={{ maxWidth: 345, height: 580 }} elevation={10} data-aos="fade-down" data-aos-duration="1000">
      <CardMedia
        component="img"
        alt="smallscale"
        height="350"
        image={smallscale}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Local Business
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{height: 110}}>
        We offer security solutions like CCTV, access control, and alarms, as well as setup for video and audio systems tailored to local small businesses. Trust us to protect your assets and enhance your business operations.
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
    );
}

export default Card3;
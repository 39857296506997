import { makeStyles } from "@material-ui/core";

export default makeStyles( theme => ({
    main:{
        display: "flex",
        height: "55vh",
        width: "60%",
        fontSize: "1.5vw",
        [theme.breakpoints.down('md')]: {
            margin: 'auto',
            height: "30vh"
          },
    },
    title:{
        width: "80%",
        flexDirection: "column",
        justifyContent: "center",
        margin: "auto",
    }
}))
import React from 'react';
import useStyles from './style';
import { Box, Button, Grid, Typography } from '@mui/material';

function Footer(props) {
    const classes = useStyles();
    return (
        <Box className={classes.footer} data-aos="fade-down" data-aos-duration="1500">
            <Grid container className={classes.footerTop}>
                <Grid item className={classes.footerTopText} xs={12} sm={12} md={12} lg={3}>
                    <Typography  variant="h3">
                        Contact Us
                    </Typography>
                    <br/>
                    <Typography  variant="p">
                        Please provide a brief information
                        which could aid us <br/> in determining
                        your current situation and needs.
                    </Typography>
                </Grid>
                <Grid item className={classes.footerTopText} xs={12} sm={12} md={12} lg={3}>
                    <Typography  variant="h5" sx={{}}>
                        Email: <br/> 
                    </Typography>
                    <Typography variant="h6" sx={{}}>
                        Contact@smartinfotechsolution.com
                    </Typography>
                    
                    <Box mt={2}  sx={{}}>
                        <Button variant="contained" size="large" sx={{}}><b>Contact Us</b></Button>
                    </Box>
                    
                </Grid>
            </Grid>
            
            <Box className={classes.footerBottom}>
                <Grid container spacing={2} sx={{alignItems: 'center', textAlign: 'left'}}>
                    <Grid item sx={12} sm={12} md={6} lg={3}>
                        <Typography variant="h5">
                            Smart IT Solution
                        </Typography>
                        <Typography variant="h7">
                            <br/>
                            12200 N Stemmons Fwy, <br/>
                            Farmers Branch, TX 75234
                            <br/>
                            <br/>
                            License #: B28417101
                            <br/>
                            <br/>
                            CEO: Tony Yoo
                        </Typography>
                    </Grid>
                    <Grid item sx={12} sm={12} md={6} lg={3}>
                    <Typography variant="h6">
                            About Us
                            <br/>
                            Clients & Vendors
                            <br/>
                            Careers
                        </Typography>
                    </Grid>
                    <Grid item sx={12} sm={12} md={6} lg={3}>

                    </Grid>
                    <Grid item sx={12} sm={12} md={6} lg={3}>
                        <Typography variant="h8" color="gray">
                            Copyright@ 
                            <br/>2023 Smart IT Solution
                        </Typography>
                    </Grid>
                </Grid>

            </Box>
        </Box>
    );
}

export default Footer;
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import commercial from '../../../../images/commercial.jpeg';

function Card1(props) {
    return (
<Card sx={{ maxWidth: 350, height: 580 }} elevation={10} data-aos="fade-down" data-aos-duration="1000">
      <CardMedia
        component="img"
        alt="commercial"
        height="350"
        image={commercial}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Commercial-Large
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{height: 110}}>
        Maximize the security of your large commercial buildings, including hotels, high-rise buildings, and commercial factories, with our comprehensive large-scale network distribution and security systems. 
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions>
    </Card>
    );
}

export default Card1;